.root {
  --header-text-color: theme('colors.body');
  @apply sticky top-0 z-40 w-full bg-main text-[color:var(--header-text-color)]
  shadow dark:bg-neutral-800 dark:shadow-lg md:py-2;
}

.translucent {
  @apply md:fixed md:bg-transparent md:shadow-none md:[--header-text-color:white];

  &:before {
    @apply pointer-events-none fixed inset-x-0 top-0 block h-[120px] content-[''] md:bg-gradient-to-b md:from-black/50 md:to-black/0;
  }
}

.content {
  @apply container flex min-h-[64px] items-center justify-between md:min-h-[72px];
}

.logo {
  @apply relative z-[20] flex h-[32px] w-[62px];
  @apply md:h-[48px] md:w-[92px];
}
