.root {
  @apply z-10 flex items-center space-x-4;
}

.controller {
  @apply hidden;
}

.links {
  @apply pointer-events-none fixed inset-x-0 top-24 z-20 hidden px-4 text-2xl font-bold;
  @apply hover:block md:pointer-events-auto;

  & a {
    @apply pointer-events-auto inline-block py-3 hover:text-yellow-300;
  }
}

.trigger {
  @apply relative flex cursor-pointer items-center justify-center rounded py-[8px] px-[6px];
  @apply outline-none;

  & .icon {
    @apply relative h-[16px] w-[20px] rotate-0 cursor-pointer transition duration-200 ease-in-out;

    & span {
      @apply absolute left-0 block h-[2px] w-[20px] rotate-0 rounded opacity-100 transition;
      @apply bg-[color:var(--header-text-color)] duration-200 ease-in-out;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        @apply origin-left-center;
      }

      &:nth-child(1) {
        @apply top-0;
      }

      &:nth-child(2) {
        @apply top-[7px];
      }

      &:nth-child(3) {
        @apply top-[14px];
      }
    }
  }
}

.overlay {
  @apply pointer-events-none fixed inset-0 z-50 block bg-neutral-900 opacity-0 transition-opacity;
}

.content {
  @apply contents md:relative md:flex md:items-center md:space-x-6;
}

.logout {
  @apply btn dark:btn-dark md:!hidden;
}

.authUser {
  @apply mr-3;
}

.userMenu {
  @apply hidden space-y-4;
  @apply md:mr-4 md:flex md:flex-row md:space-x-4 md:space-y-0;
}

.authenticated {
  & .userMenu {
    @apply hidden;
  }
}
  .controller:checked + .content {
    & .overlay {
      @apply pointer-events-auto opacity-100 md:opacity-0;
    }

    & .links {
      @apply block rounded md:absolute md:inset-auto md:top-[48px] md:-right-[4px] md:w-[200px];
      @apply md:bg-neutral-900 md:text-base md:font-normal;

      &:before {
        content: ' ';
        @apply absolute right-[6px] -top-[2px] hidden h-[24px] w-[24px] rotate-45 bg-neutral-900 md:block;
      }
    }

    & .trigger {
      @apply relative z-50;

      & .icon {
        & span {
          @apply bg-white;

          &:nth-child(1) {
            @apply top-[0] left-1 rotate-45;
          }

          &:nth-child(2) {
            @apply w-0 opacity-0;
          }

          &:nth-child(3) {
            @apply top-[14px] left-1 -rotate-45;
          }
        }
      }
    }

    & .userMenu {
      @apply fixed inset-x-0 bottom-0 z-50 flex flex-col items-center pb-12 md:static md:flex-row md:pb-0;
    }

    .logout {
      @apply md:block btn-dark;
    }
  }


.trigger:hover {
  @media (min-width: theme('screens.md')) {
    @apply lg:bg-neutral-900/20 lg:dark:bg-neutral-600;

    & > .links {
      @apply block md:absolute md:inset-auto md:top-[48px] md:-right-[4px] md:w-[200px] md:rounded;
      @apply md:bg-neutral-900 md:text-base md:font-normal;

      &:after {
        content: ' ';
        @apply absolute right-[6px] -top-[2px] h-[24px] w-[24px] rotate-45 bg-neutral-900 md:block;
      }

      &:before {
        content: ' ';
        @apply absolute right-0 -top-[20px]  h-[24px] w-[50px] md:block;
      }

      & a {
        @apply block;
      }
    }
  }
}
