.root {
  @apply container mx-auto py-8 text-center text-lg md:mt-12;
}

.main {
  @apply space-y-8 lg:flex lg:flex-row-reverse lg:justify-between lg:space-y-0;
}

.apps {
  @apply space-y-4 lg:max-w-[300px] lg:space-y-6 xl:max-w-none;
}

.menu {
  @apply justify-center pt-4 md:flex md:flex-wrap md:space-y-0 lg:justify-start lg:pt-0;

  & li {
    @apply pb-4 md:mr-6;
  }

  & a {
    @apply whitespace-nowrap hover:underline;
  }
}

.social {
  @apply mt-6 flex justify-center space-x-6 text-2xl lg:justify-start;
}

.copy {
  @apply mt-8 space-y-3 text-sm text-support md:mt-12;

  & a {
    @apply underline hover:no-underline;
  }

  & .adhoc {
    @apply inline-flex items-center space-x-2 no-underline hover:text-blue-500;
  }
}
